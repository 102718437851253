<template>
  <div :class="$style.container">
    <header>
      <h1 :class="$style.header">
        mowgli
      </h1>
      <a
        :class="$style.headerLink"
        href="#/press"
      >
        Press Kit
      </a>
    </header>
    <section :class="$style.content">
      <div
        v-for="(genre, index) in genres"
        :key="index"
        :class="$style.genreBlock"
      >
        <div :class="$style.genreHeader">
          <div
            v-html="genre.displayName || genre.name"
            :style="{
              ...getGenreBackground(genre),
              ...(genre.lightBackground && { color: 'black' }),
              ...(genre.bgPosition && { backgroundPosition: genre.bgPosition }),
            }"
          />
        </div>
        <div
          :class="$style.genreBlockContent"
        >
          <ul
            :class="$style.mixItemList"
            :style="getGenreBackground(genre)"
          >
            <li
              :class="$style.mixItem"
              v-for="(mix, index) in genreMixes(genre.name)"
              :key="index"
            >
              <a
                :class="$style.link"
                :href="getMixHref(mix)"
                rel="noopener noreferrer"
                target="_blank"
                @click="handleLinkClick(mix)"
              >
                <img
                  :class="$style.mixImage"
                  :src="require(`@/assets/images/mixes/${mix.image}`)"
                >
              </a>
              <a
                :class="[$style.link, $style.mixTextLink]"
                :href="getMixHref(mix)"
                rel="noopener noreferrer"
                target="_blank"
                @click="handleLinkClick(mix)"
              >
                <div :class="$style.mixText">
                  <div>{{ mix.name }}</div>
                  <div :class="$style.mixCreatedText">{{ dateCreatedText(mix.createdDate) }}</div>
                </div>
              </a>
              <router-link
                :to="`/${getMixLink(mix)}`"
                :class="[$style.link, $style.listLink]"
              >
                <img
                  :class="$style.listIcon"
                  src="@/assets/images/icons/list.svg"
                >
              </router-link>
              <a
                :class="[$style.link, $style.downloadLink, {
                  [$style.downloadLinkUnavailable]: !mix.mp3,
                }]"
                download
                :href="mix.mp3"
                @click="handleDownloadLinkClick(mix)"
              >
                <img
                  :class="$style.downloadIcon"
                  src="@/assets/images/icons/download.svg"
                >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Footer from './Footer.vue';
import moment from 'moment';
import genres from '@/constants/Genres';
import mixes from '@/constants/Mixes';

export default {
  name: 'home',
  components: {
    Footer,
  },
  data: () => ({
    genres,
    mixes,
    useMixcloudLink: false,
  }),
  methods: {
    dateCreatedText(date) {
      return moment(date).format('MMM YYYY');
    },
    genreMixes(genre) {
      return mixes.filter(mix => mix.genre === genre);
    },
    getGenreBackground(genre) {
      return {
        backgroundColor: genre.bgColor,
        backgroundImage: `url(${require(`@/assets/images/genres/${genre.bgImage}`)})`,
      };
    },
    getMixHref(mix) {
      if (!this.useMixcloudLink && mix.mp3) {
        // eslint-disable-next-line
        return require(`@/assets/mp3s/${mix.mp3}`);
      }
      return mix.url;
    },
    getMixLink(mix) {
      // If mp3 is `fall-mix-4.mp3`, the link will be `/fall-mix-4`
      const dotIndex = mix.mp3.indexOf('.');
      return mix.mp3.slice(0, dotIndex);
    },
    handleDownloadLinkClick(mix) {
      if (window.gtag) {
        window.gtag('event', 'link-click', {
          event_label: mix.name,
        });
        window.gtag('event', 'download-click', {
          event_label: mix.name,
        });
      }
    },
    handleLinkClick(mix) {
      if (window.gtag) {
        window.gtag('event', 'link-click', {
          event_label: mix.name,
        });
      }
    },
  },
};
</script>

<style module lang="scss">
@value mixBackgroundOpacity: 0.7;
@value mixBackgroundOpacityHighlighted: 0.8;
@value mixLinkBackground: linear-gradient(180deg, rgba(50, 50, 50, mixBackgroundOpacity) 0%, rgba(70, 70, 70, mixBackgroundOpacity) 100%);
@value mixLinkBackgroundHighlighted: linear-gradient(180deg, rgba(35, 35, 35, mixBackgroundOpacityHighlighted) 0%, rgba(45, 45, 45, mixBackgroundOpacityHighlighted) 100%);

.container {
  background-color: #222;
  color: white;
  height: 100%;
  overflow-y: auto;
  position: relative;
  user-select: none;
}

.header {
  font-family: 'Oxin Brush';
  font-size: 80px;
  margin: 0;
  padding: 60px 50px;
  position: sticky;
}

.headerLink {
  color: rgba(255, 255, 255, 0.8);
  position: absolute;
  right: 30px;
  text-decoration: none;
  top: 30px;
}

.headerLink:hover { color: rgba(255, 255, 255, 0.9); }

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 20px;
}

.genreBlock {
  -webkit-transform: translate3d(0,0,0);
  display: inline-block;
  margin: 0 20px 40px;
  max-width: 600px;
  position: relative;
  vertical-align: top;
  width: 100%;
}

.genreHeader {
  box-shadow: 0 2px 15px rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Major Mono Display', monospace;
  font-size: 28px;
  font-style: italic;
  font-weight: 800;
  overflow: hidden;
  transform: skewX(-20deg);
}

.genreHeader > * {
  background-size: cover;
  display: inline-block;
  left: -30px;
  padding: 40px 50px;
  position: relative;
  transform: skewX(20deg);
  width: calc(100% + 60px);
}

.genreBlockContent {
  margin: 0;
  padding: 0 24px;
  text-align: center;
}

.mixItemList {
  background-attachment: fixed;
  background-position: 100% 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: inline-block;
  margin: 0;
  max-width: 480px;
  padding: 0;
  text-align: left;
  width: 100%;
}

.mixItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  list-style-type: none;
}

.mixItem > * { height: 50px; }

.mixTextLink { flex: 1; }

.mixImage {
  height: 50px;
  width: 50px;
}

.mixText,
.downloadLink,
.listLink {
  background: mixLinkBackground;
  &:hover {
    background: mixLinkBackgroundHighlighted;
  }
}

.mixText {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 50px;
  justify-content: center;
  padding: 20px;
}

.mixCreatedText {
  font-size: 11px;
  margin-top: 2px;
}

.downloadLink,
.listLink {
  align-items: center;
  border-left: 1px solid rgba(200, 200, 200, 0.2);
  display: flex;
  height: 50px;
  padding: 0 20px;
}

.downloadLinkUnavailable {
  cursor: default;
  &:hover {
    background: mixLinkBackground;
  }

  .downloadIcon { visibility: hidden; }
}

.downloadIcon,
.listIcon {
  opacity: 0.8;
}

.downloadIcon {
  height: 16px;
  width: 14px;
}

.listIcon {
  height: 19px;
  position: relative;
  top: 1.5px;
  width: 14px;
}

@media (max-width: 568px) {
  .header {
    font-size: 80px;
    padding: 30px 40px;
  }
}

@media (max-width: 420px) {
  .header {
    font-size: 55px;
    padding: 30px 0;
  }

  .genreHeader { font-size: 22px; }
  .genreHeader > * { padding: 32px 50px; }
  .content { padding: 0 10px; }

  .mixText {
    font-size: 13px;
    padding: 15px;
  }

  .mixCreatedText { font-size: 10px; }
  .downloadLink { padding: 0 15px; }
}

/* ------- Miscellaneous ------ */
.link {
  color: inherit;
  text-decoration: none;
}
</style>
