<template>
  <footer :class="$style.footer">
    <a
      :class="$style.link"
      href="https://www.soundcloud.com/itsmowgli"
      rel="noopener noreferrer"
      target="_blank"
    >Soundcloud</a>
    |
    <a
      :class="$style.link"
      href="https://www.mixcloud.com/itsmowgli"
      rel="noopener noreferrer"
      target="_blank"
    >Mixcloud</a>
    |
    <a
      :class="$style.link"
      href="mailto:dc.mowgli@gmail.com"
    >Contact</a>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style module lang="scss">
.footer {
  font-size: 13px;
  opacity: 0.8;
  padding: 20px 20px 40px;
  text-align: center;

  .link {
    margin: 0 10px;

    &:hover {
      opacity: 1;
      text-decoration: underline;
    }
  }
}

.link {
  color: inherit;
  text-decoration: none;
}
</style>
