<template>
  <div :class="$style.container">
    <div :class="$style.logo">
      mowgli
    </div>
    <div :class="[$style.section, $style.imageSection]">
      <img src="../assets/images/mowgli-blue.png" />
    </div>
    <div :class="$style.section">
      <div :class="$style.bioContainer">
        <p :class="$style.bio">
          Originally from Washington, DC, Mowgli has been DJing in the Boulder and Denver scene for the last 6 years.
          Known for spinning all types of electronic dance music from future bass to heavy trap and everything in between,
          Mowgli brings the energy and livens up the party with his unique upbeat sound.
        </p>
      </div>
    </div>
    <div :class="$style.section">
      <div :class="$style.embedContainer">
        <iframe
          width="500"
          height="180"
          scrolling="no"
          frameborder="no"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1171341565&auto_play=false&show_user=true&show_teaser=true&visual=true">
        </iframe>
        <iframe
          width="500"
          height="180"
          scrolling="no"
          frameborder="no"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/926344294&auto_play=false&show_user=true&show_teaser=true&visual=true">
        </iframe>
      </div>
    </div>
    <div :class="$style.footerContainer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from './Footer.vue';

export default {
  name: 'PressKit',
  components: {
    Footer,
  },
};
</script>

<style module lang="scss">
@value sectionHeight: 320px;

.container {
  background-attachment: fixed;
  background-image: url('../assets/images/ferns.jpg');
  background-position: center;
  background-size: cover;
  color: #222;
  min-height: 100vh;
  text-align: left;
}

.logo {
  color: #f0f0f0;
  font-family: 'Oxin Brush';
  font-size: 80px;
  padding: 30px 50px;
}

.section {
  background-color: rgba(20, 20, 20, 0.9);
  margin: 60px 0;
  padding: 0 100px;
  position: relative;
}

.imageSection {
  height: sectionHeight;
  img { height: sectionHeight; }
}

.bioContainer {
  text-align: center;
}

.bio {
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  font-family: Cambria;
  font-size: 18px;
  line-height: 28px;
  padding: 10px 0;
  text-align: left;
  width: 600px;
}

.embedContainer {
  display: flex;
  justify-content: space-between;
}

.footerContainer {
  background-color: rgba(20, 20, 20, 0.9);
  color: white;
}
</style>
