/**
 * An array of objects that contains information about each mix
 * @param name - The name of the mix displayed
 * @param genre - The genre block the mix will be displayed in
 * @param image - The image filename to be found in 'assets/images/mixes/'
 * @param mp3 - The mp3 filename to be found in 'assets/mp3s/'
 * @param createdDate - The date the mix was made, to be displayed directly
 * @param length - The length of the mix, to be displayed directly
 * @param tracklist - An array of strings representing the tracklist in order
 */
export default [
  {
    name: 'Festival Mix 5',
    genre: 'Hard',
    image: 'fest-mix-5.jpg',
    url: '',
    mp3: 'fest-mix-5.mp3',
    createdDate: '2023-11-30',
    length: '41:52',
    tracklist: [
      'jawns - erotica',
      'w in k - over',
      'nghtmre, lizzy land - on my mind (baaric & jpky remix)',
      'isoxo - pressure',
      'skrillex & nai barghouti - xena (ibac & joshua tu flip)',
      'skrillex, aluna & kito - inhale exhale',
      'remk - breakwater',
      'isoxo - ok!ok!ok! (with wavedash)',
      'moore kismet - run away!',
      'lab group - hd (ft jawnza)',
      'cesco, hamdi - swing king',
      'peekaboo & lyny - like that',
      'peekaboo & zeds dead - scared',
      'lyny x fly - pulse',
      'lyny - noxious (svmmie flip)',
      'peekaboo - riddle',
      'isoxo - moonspell',
      'chee - spangled',
      'quix - trust (vip)',
      'creeds - push up (lsdream & pierce remix)',
      'isoxo - ignorant',
      'g jones & isoxo - say what',
      'sexyy red - skeeyee (detre flip)',
      'cptr x hemilotl - w48',
      'mozey - ladykillaz',
      'machinedrum & holly - berry patch (halogenix remix)',
      'rl grime & juelz - formula (imanu remix)',
      'montell2099, xira & sublab - exodia (fredrick remix)',
      'isoxo - shypop',
      'skrillex & swae lee - mixed signals',
      'player dave - dumb (dnb edit)',
    ],
  },
  {
    name: 'Club Work Mix 5',
    genre: 'House',
    image: 'club-work-mix-5.jpg',
    url: '',
    mp3: 'club-work-mix-5.mp3',
    createdDate: '2023-3-10',
    length: '22:36',
    tracklist: [
      'whethan - clouds',
      'daehan - joyride',
      'glance - timeout',
      'p3pper, jibaan - lumen',
      'doja cat - like that (nabil! flip)',
      'the chainsmokers & coldplay - something just like this (4b remix)',
      'rytmeklubben - seen (hikeii flip)',
      'nightcorey - make me go',
      'succducc - me & u',
      'taylor swift - shake it off',
      'valentino khan - deep down low (g-buck edit)',
      'leemz & yungphnx - got back?',
      'skrillex & must die! - vips (scrvp remix)',
      'snappy jit - rockin it (shintaro remix)',
      'oakk - bota',
      'eqal - she dont pt 2',
      'lil pump - flex like ouuu (leemz bootleg)',
      'wiwek - stereoplein',
      'sophie - vyzee',
      'javascript - p!nky ring (ca$h bandicoot remix)',
      'ahadadream - hydration',
      'kovalenco gennadi - moda',
    ],
  }, {
    name: 'Fall Mix 6',
    genre: 'Vibey',
    image: 'fall-mix-6.png',
    url: 'https://www.mixcloud.com/itsmowgli/fall-mix-6',
    mp3: 'fall-mix-6.mp3',
    createdDate: '2022-11-29',
    length: '39:07',
    tracklist: [
      'eyezig & pleeg - good for nothing',
      'charlesthefirst - in pieces',
      'dounia - greygoose',
      'of the trees - harvest',
      'alison wonderland - ignore',
      'griz - feel it all',
      'ninajirachi - dracodraco',
      'imanu & droeloe - shoyu',
      'ekcle - pearl jigsaw',
      'nosaj thing - fog',
      'duffrey - lurch',
      'baribal & 3lo - golden hour',
      'kehlani - gangsta',
      'dossyx - stay awake',
      'two lanes - feel',
      'cool teens - dying to know',
      'tails - close2me',
      'last heroes - threads',
      'camoufly - million years',
      'floral frequencies - inner lotus',
      'parkbreezy - keep it cool',
      'fly - frost',
      'dot - charley',
      'fugue - healing',
    ],
  }, {
    name: 'Festival Mix 4',
    genre: 'Hard',
    image: 'fest-mix-4.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/festival-mix-4',
    mp3: 'fest-mix-4.mp3',
    createdDate: '2021-12-03',
    length: '53:32',
    tracklist: [
      'yurie & dyzphoria - lapse',
      'masayoshi iimori - realm',
      'g jones - immortal light',
      'secret recipe - interstellar symphonies (clozee remix)',
      'hot mess - old skool',
      'quix - lighthouse',
      'spektrum - invade',
      'erbes - like me',
      'saymyname - spitfire',
      'minesweepa - stop talking',
      'mob mind - swagger juice',
      'duke & jones - lost in motion',
      'kowta - data transfer',
      'just a gent - aliens',
      'the kid laroi, justin beiber - stay',
      'hydraulix - cheer squad',
      'oakk - eww',
      'adiidas, ashez - do it like this',
      'juelz - exile',
      'um.. - skrillex 6',
      'clozee - perfect (of the trees remix)',
      'gold-ish - different paths (glty remix)',
      'danny brown - lie4 (acapella)',
      'remk - breakwater',
      'tiger drool - saber tooth (quix remix)',
      'tyraz - smog',
      'kursa - micro',
      'tvboo & chompaa - drugs!',
      'ookay - dinero',
      'minesweepa - black mclaren',
      'remk - high tide',
      'isoxo - antihaven',
      'remk - shoota',
      'arrested youth - a painting in paris (quix remix)',
      'red fingers - in my mind',
      'barely alive & nyptane - electric lady',
      'wavedash - status quo',
      'remk - lowrider',
      'isoxo - nightrealm',
      'dirty aduio & nxsty - inferno',
      'spag heddy - zoom',
      'bladee - drama',
    ],
  }, {
    name: 'Fall Mix 5',
    genre: 'Vibey',
    image: 'fallmix5.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/fall-mix-5/',
    mp3: 'fall-mix-5.mp3',
    createdDate: '2021-09-07',
    length: '27:37',
    tracklist: [
      'skrillex - kliptown empryean',
      'skrillex - summit',
      'the chain gang of 1974 - forget (aobeats remix)',
      'anh & quickly, quickly - preach',
      'flume - say it',
      'charlesthefirst - the mist (w/ clozee)',
      'fly - shadows',
      'charlesthefirst - clarity',
      'tails - wanderer',
      'beak nasty - pure bliss (g-space remix)',
      'bronze whale - lets go walk',
      'ilo ilo - golden',
      'soft glas - pink mirror (demo)',
      'madnap - slow down',
      'handbook - thats fine',
      'ctrl+r - zoom zoom forever',
      'zenaware - rush over me',
    ],
  }, {
    name: 'Fall Mix 4',
    genre: 'Vibey',
    image: 'fall-mix-4.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/fall-mix-4/',
    mp3: 'fall-mix-4.mp3',
    createdDate: '2020-11-09',
    length: '40:01',
    tracklist: [
      'g jones - in your head (tsu nami remix)',
      'clozee - aurora',
      'dillon francis & alison wonderland - lost my mind',
      'kumarion - want it',
      'charlesthefirst - mercy falls',
      'sumthin sumthin - trauma',
      'dissolv - time technician',
      'hae - whip my hair',
      'inzo, blookah, align - it goes in waves',
      'skye chai - feel.blue',
      'mac miller - self care',
      'charlesthefirst - beacon',
      'phaeleh - badman',
      'charlesthefirst - balance',
      'edamame - sable (cofresi remix)',
      'moore kismet - flair',
      'ekali - braids',
      'bd hbt x snuffy - sunk',
      'duke & jones - playboi cartii x gypsy women',
      'moshe - cool hundred',
      'ill-esha - como la chica (instrumental)',
      'playboi cartii - let it go',
      'clozee - desert',
      'mr carmack - remember to remember',
      'shampoo puppy - bun da hype',
      'duke & jones - detox',
      'barclay crenshaw - the gene sequence',
      'disclosure - you & me (baauer remix)',
      'notes - know you',
      'galimatias - gps',
    ],
  }, {
    name: 'Festival Mix 3',
    genre: 'Hard',
    image: 'fest-mix-3.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/fest-mix-2020/',
    mp3: 'fest-mix-3.mp3',
    createdDate: '2020-03-23',
    length: '48:09',
    tracklist: [
      'snavs & unkwn - i can feel',
      'quix - huhhh',
      'party favor & nonsens - gasoline',
      's&m - scream',
      'boombox cartel - jefe',
      'duke & jones - rust',
      'sikdope - follow',
      'bd hbt x leet - big festival banger',
      'skrillex - fuji opener',
      'duke & jones x kaku - higher',
      'benzi - whatcha gon do (quix remix)',
      'travis scott - hitr (so dope vip)',
      'ekali & nitti gritti - power',
      'flo milli - beef flomix',
      'holly x asap tty x nxsty - til i die',
      'stuca & eugene - mind',
      'quix - fiji day 4',
      'dreamville, bas, jid - costa rica',
      'juelz - winter',
      'effin - uncomfortable',
      'project paradis - yaimt',
      'isoxo - ground',
      'zeds dead x subtronics - bumpy teeth',
      'pixel terror - submerge',
      'walter wilde x subdocta - suga',
      'fvce down - not even you',
      'kayzo x subtronics - braincase',
      '4b x purowuan - atomic bomb',
      'eliminate - weeble wobble vip',
      'griz & subtronics - griztronics (luca lush remix)',
      'dbstf - angels & demons (darren styles edit)',
      'elephante - red smoke (yookie remix)',
      'saymyname - knockout',
      'virtual riot - lost it (vip)',
      'rl grime & juelz - formula',
      'hopsteady & spektrum - got damn',
      'gravedgr - death row',
      'rick ross - trap trap trap',
      'cozway & jon casey - ambush',
      'atliens - fuck that',
      'isoxo - click (mix rip)',
      'juelz - babyface savage (juelz & tsu nami remix)',
      'yvng jalapeno & dabow - slime ball',
      'skrillex, boys noize, ty dolla sign - midnight hour (remk flip)',
      'hairitage - the movement',
      'dillon francis x alison wonderland - lost my mind (wildlyf remix)',
      'skrillex - mumbai power',
    ],
  }, {
    name: 'Soft Mix 2',
    genre: 'Soft',
    image: 'softmix2.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/soft-mix-2/',
    mp3: 'soft-mix-2.mp3',
    createdDate: '2019-01-05',
    length: '43:19',
    tracklist: [
      'lege kale - pacific ave (longmountain edit)',
      'alina baraz & galimatias - maybe (instrumental)',
      'alina baraz & galimatias - fantasy (instrumental)',
      'nude plus - need space',
      'black grapefruit - end',
      'esta - dreamland',
      'lido - solsbury hill',
      'alina baraz & galimatias - make you feel (instrumental)',
      'zodivk - yours (+)',
      'yaeji - passionfruit (dj remix)',
      'sevdaliza - that other girl',
      'esta - million',
      'aobeats - sickalicious',
      'robokid - survive',
      'soso - roost',
      'tennyson - no answer',
      'pls&ty - motives (penthouse penthouse remix)',
      'junior state - levitate',
      'tiedye kye - alternate reality 3035',
      'laxcity - good morning',
      'coryayo - january 1st',
      'feki - run away',
      'cloudchord x g mills - right of passage',
      'cloudchord x g mills - tuesday in the park',
      'future - fuck up some commas (acapella)',
      'charlesthefirst - balance',
      'mr carmack - rogue',
      'hex cougar - agatha',
      'cashmere cat - victoria\'s veil',
      'midnight kids - find our way (night drive edit)',
      'lost kings - stuck',
      'manila killa - youth',
      'kidswaste & manila killa - tonight (speaker of the house remix)',
    ],
  }, {
    name: 'Fall Mix 3',
    genre: 'Vibey',
    image: 'fallmix2019.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/fall-mix-2019/',
    mp3: 'fall-mix-3.mp3',
    createdDate: '2019-11-01',
    length: '34:51',
    tracklist: [
      'big wild - 109',
      'josh pan - hermes',
      'yheti x the widdler - new ideas',
      'andru - all night',
      'duke & jones - spectral',
      'party thieves & atliens - chief (nebbra remix)',
      'medasin - silver',
      'hmu x adiidas - just do it',
      'wilkjayy & eugene cam - we dont talk anymore',
      'jack cates - staring into space',
      'rezz - relax',
      'elex - local',
      'louis the child - from here',
      'great dane - drop top',
      'cozway - bamboo',
      'jerry folk - hey',
      'great dane - dirty daddy',
      'skysia - circles',
      'flume & eprom - spring',
      'chynna - the conversation (charlesthefirst remix)',
      'rivr - traces',
      'alina baraz - lavender and velvet',
      'louis the child - slow down love',
      'ujico*/snails house - mal d\'amour',
      'medasin - work for you',
      'duskus - where to go',
      'brunswick - oh well (klrx edit)',
    ],
  }, {
    name: 'Club Work Mix 4',
    genre: 'House',
    image: 'clubworkmix4.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/club-work-mix-4/',
    mp3: 'club-work-mix-4.mp3',
    createdDate: '2019-07-22',
    length: '41:24',
    tracklist: [
      'one true god - alkaholik',
      'mm - 9th ritual',
      'saint & uniique - yo (i\'m lit)',
      'biggs - whistle',
      'arme - teriyaki tool',
      'ski mask the god - where\'s the blow',
      'mm - qbt18',
      'lowdown & lozz - 6am',
      'sumr camp - wait a minute',
      'one true god - deep in the cut',
      'joyryde - give my love',
      'bushbaby - darker',
      'i_o - body rock',
      'matroda - you can',
      'yaeji - raingurl',
      'vices - essa (equator club remix)',
      'qrion - bring back (onlytom remix)',
      'riton - rinse & repeat (preditah remix)',
      'kito & swick - video call',
      'brenmar & shaqtheproducer - drop it',
      'hi tom - summer plants',
      'drippy dolphin - heaveeen',
      'sage the gemini - gas pedal / owl city (ryan hemsworth bootleg)',
      'fluke nukes - shoot',
      'leikeli47 - girl blunt',
      'blueface - thotiana (smoked out remix)',
      'dog blood - break law',
      'arme - ba',
      'bhad bhabie - hi bich (young luxenberg remix)',
      'alrt - fire',
      'dj snake - propaganda (ghostdragon remix)',
      'ramengvrl - im da man (erik soto remix)',
      'more giraffes - not cool (party pupils remix)',
      'cashmere cat - trust nobody (basedprince & dj flex remix)',
    ],
  }, {
    name: 'Festival Mix 2',
    genre: 'Hard',
    image: 'fest-mix-2.gif',
    url: 'https://www.mixcloud.com/itsmowgli/festival-mix-2018/',
    mp3: 'fest-mix-2.mp3',
    createdDate: '2018-12-26',
    length: '48:09',
    tracklist: [
      'jaykode x party thieves - origin',
      'ducky - work (krymsa remix)',
      'diplo, french montana, lil pump - welcome to my party (valentino khan remix)',
      'sfam x boogie t - out my face',
      'eliminate - house party',
      'gta & tisoki - botellas',
      'kompany - override',
      'sludge - nobody safe',
      'boombox cartel & flosstradamus - id',
      'dmvu - bloccd (tynan flip)',
      'rl grime - era',
      'quix - get down (intro)',
      'travis scott - sicko mode (skrillex remix)',
      'memba & wide awake - vexed',
      'rl grime - omg',
      'ghastly - this song scares people',
      'gravedgr - rampage (unkwn remix)',
      'capshun - solace',
      '4b - ice cold',
      'skrillex & habstrakt - chicken soup (freekill remix)',
      'valee - womp womp (yehme2 remix)',
      'rickyxsan x jaykode - started',
      'duke & jones - toxin',
      'peekaboo, g-rex - babtunde (mowgli\'s mo bamba edit)',
      'tascione & tynvn - time',
      'yookie - brain warp',
      'avance - yacht yacht',
      'zeds dead & jauz - lights go down (duke & jones remix)',
      'ekali & slumberjack - helios (fomo remix)',
      'yookie - escape',
      'excision - generator (eliminate remix)',
      'dnmo & sub urban - broken (stratus remix)',
      'havok roth & wildlyf - stellar',
      'rl grime - omg (myrne remix)',
      'virtual riot - show up',
      'roddy rich - die young (bishu remix)',
      'shuba - dont stop',
      'g jones - time',
      'troyboi - do you (msft remix)',
      'slumberjack x troyboi - solid',
      'nxsty - terra',
      'blvc sand - go to the sto',
      'holly - missu',
      'minesweepa - low key',
      'translee - hd bling',
      'ekali x denzel curry - babylon (badrapper x twerl flip)',
      'badrapper x nxsty - catch my vibe',
      'rl grime - undo',
      'krne x jupe - seventeen',
      'eptic - watch out (acraze remix) (owen wilson wow edit)',
      'rl grime - light me up (quix remix)',
    ],
  }, {
    name: 'Fall Mix 2',
    genre: 'Vibey',
    image: 'fallvibesmix2018.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/fall-vibey-mix-2018/',
    mp3: 'fall-mix-2.mp3',
    createdDate: '2018-11-11',
    length: '28:50',
    tracklist: [
      'ekali & mossy. - stay hollow',
      'elmari - pleasant',
      'd - one',
      'erick di - desse jeito',
      'erick di - linha de frente',
      'anh & quickly quickly - phases',
      'fkj & masego - tadow',
      'basecamp - watch my back (heavy mellow remix)',
      'sango - so voce',
      'emawk - hermosa',
      'breaux - bronze serenade (breaux re-imagination)',
      'poid x 92elm - flowers',
      'rich the kids - new freezer',
      'subtoll - bamboo',
      'galimatias - south',
      'alina baraz & galimatias - show me (instrumental)',
      'mac miller - jet fuel',
    ],
  }, {
    name: 'Club Work Mix 3',
    genre: 'House',
    image: 'clubworkmix3.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/club-work-mix-pt-3/',
    mp3: 'club-work-mix-3.mp3',
    createdDate: '2018-07-31',
    length: '25:13',
    tracklist: [
      'rawtek & henry fong & iamstylezmusic - vibrate',
      'calvin harris, dua lipa - one kiss (jauz remix)',
      'tony quattro - fuerza',
      'valentino khan - lick it (meaux green "lick it good" edit)',
      'joyryde - speed trap',
      'childish gambino - this is america (matroda remix)',
      'jauz - feel the volume (joyryde reverse remix)',
      'halsey - alone (calvin harris remix)',
      'don diablo vs modjo - momentum vs lady hear me tonight (wedamnz mashup)',
      'ill phil - clear a mans throat (2016)',
      'lil pump - essskeeetit (benzi x chuwe edit)',
      'lil pump - flex like ouuu (leemz bootleg)',
      'bro safari, dillon francis & salvatore ganacci - xl (tisoki remix)',
      'quintino - carnival',
      'cardi b, bad bunny & j balvin - i like it (dillon francis remix)',
      'dj snake - a different way (bro safari & etc!etc! remix)',
      'etc!etc! - el nomber one',
      'dillon francis - candy ft. snappy jit',
      'ed sheeran - shape of you (major lazer remix)',
      'major lazer - come on to me (major lazer vs etc!etc! remix)',
      'dillon francis & skrillex - bun up the dance',
      'zedd, gray - the middle',
    ],
  }, {
    name: 'Hotbox 001 with Mowgli',
    genre: 'Variety',
    image: 'hotbox.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/hotbox-001-mowgli/',
    mp3: 'hotbox-mix.mp3',
    createdDate: '2018-06-06',
    length: '31:08',
    tracklist: [
      'mr carmack - lemons',
      'eliminate & minesweepa - hotel trash',
      'missy elliott - work it (falcons bootleg)',
      'noax - imma reach to that mf',
      'hucci - hitta (vip)',
      'juelz - wwyd',
      'zooly - wanna',
      'rawtek - cardi boombahton',
      'valentino khan - hello (nitti gritti bootleg)',
      'lil pump - gucci gang (sharps remix)',
      'cashmere cat - miss you (akira akira & hikeii bootleg)',
      'mr carmack & promnite - fdt',
      'whereisalex - yellow',
      'nghtmre - get back',
      'cardi b - i like it',
      'delay - f8',
      'ekali x troyboi - truth',
      'quix - storm (hydraulix remix)',
      'quix - influences',
      'yehme2 x ricky remedy - hitp',
      'hex cougar - all the way down',
      'loudpvck & killagraham - liar',
      'rl grime - reims (enschway & luude remix)',
      'virtual self - ghost voice (gta psy edit)',
      'alison wonderland - happy place',
      'nghtmre & dillon francis - another dimension (yookie remix)',
      'yookie - project 9',
      'atliens - invasion',
      'migos - walk it talk it',
      'uz - trap shit v22 (aylen & baewatch remix)',
      'galantis & hook n sling - love on me (shndo remix)',
    ],
  }, {
    name: 'Big Mood Mix',
    genre: 'Variety',
    image: 'bigmoodmix.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/big-mood-mix/',
    mp3: 'big-mood-mix.mp3',
    createdDate: '2018-05-07',
    length: '51:35',
    tracklist: [
      'big wild - ayahuasca at night',
      'madeintyo - skateboard p',
      'project paradis - odo',
      'herzeloyde - knowone',
      'future & young thug - patek water',
      'x&g - kingpin (mr vandal remix)',
      'lil uzi vert - xo tour llif3',
      'mr carmack - jay-z',
      'djemba djemba - plum blossom',
      'kreayshawn - gucci gucci (mura masa edit)',
      'x&g - damn',
      'aobeats - nxg',
      'drake - gods plan',
      'zhu - chasing marrakech',
      'ekali x troyboi - truth',
      'quix - storm (hydraulix remix)',
      'rezz x isqa - psycho',
      'kendrick lamar - kings dead',
      'cray - 4never',
      'louis the child - right to it',
      'krane, dnmo & post malone - sakura (benzi x brycem88 edit)',
      'elliphant - revolusion (Uz remix)',
      'badrapper x benji - at the club',
      'falcons - want your body',
      'mr carmack x arnold - muney (gimme dat)',
      'run the jewels - oh my darling (flosstradamus remix)',
      'falcons - nonsense',
      'blvc svnd - diva',
      'uz - lobby',
      'mr carmack - kick it up',
      'what so not & skrillex - goh',
      'louis the child & icona pop - weekend (lexxmatiq remix)',
      'josh pan x sakuraburst - cudi',
      'troyboi & grandtheft - sneaky',
      'fki 1st - good gas',
      'eugene cam - overworld',
      'nitti gritti - fear',
      'major lazer - pon de floor (noise frenzy bootleg)',
      'sophie - faceshopping (madeaux remix)',
      'kendrick lamar - x',
      'lektrique x autodidakt - shots fired',
      'destinys child - lose my breath (lazerpiff refix)',
      'dj tray & kayy drizz - pound cake',
      'kid antoine - expected encounter',
      'cardi b - bartier cardi',
      'hmu x adiidas - just do it',
      'uz - magellan',
      '2chainz - its a vibe',
    ],
  }, {
    name: 'Fall Mix 1',
    genre: 'Vibey',
    image: 'fallvibesmix2017.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/fall-mix-2017/',
    mp3: 'fall-mix-1.mp3',
    createdDate: '2017-10-22',
    length: '39:46',
    tracklist: [
      'tinashe - 2 on (tokimonsta remix)',
      'delay - adolescence',
      'umru x holly - sacrifice',
      'mr carmack - dna',
      'air france - no way down (mossy. version)',
      'jerry folk - life under water',
      'wolfe - through with you',
      'mossy. - glass',
      'eljay - no pressure',
      'cashmere cat - mirror maru',
      'stooki sound & mura masa - rvre',
      'krs. - circles riddim',
      'yookie - subs',
      'sumthin sumthin - hiccup',
      'djemba djemba - plum blossom',
      'droeloe - zzz',
      'just a gent - hold',
      'josh pan - fetch',
      'sfam - go',
      'sofasound - lost in you',
      'vindata - right now',
      'manila killa x aobeats - im ok (swaidy remix)',
      'droeloe - sunburn',
      'louis the child - love is alive',
      'shaun frank - no future',
      'allie x - paper love',
      'brasstracks - favorite',
      'kvn - birth',
      'sagun - ill keep you safe (ft. shiloh',
    ],
  }, {
    name: 'Festival Mix 1',
    genre: 'Hard',
    image: 'fest-mix-1.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/festival-mix-2017/',
    mp3: 'fest-mix-1.mp3',
    createdDate: '2017-06-29',
    length: '56:31',
    tracklist: [
      'virtual riot - never let me go',
      'hydraulix - head bang',
      'atliens - alchemy',
      'g jones - lavender town (vip)',
      'landyn - death by firing squad',
      'oski - flex',
      'andrew fresko x lit lords - fire squad',
      'excision & dion timmer - final boss',
      'grandtheft & delaney jane - easy go (grandtheft vip)',
      'ugly god - water (falcons & promnite remix)',
      'stooki sound - lagoon',
      'party thieves & instant party - savages',
      'carnage & junkie kid - btfwd (terror bass remix)',
      'snafu - los reyes (oe$chy remix)',
      'spag heddy - samir (vip)',
      'ekali & krane - akira',
      'habstrakt - clowns (hydraulix & oski remix)',
      'yellow claw - hold on to me',
      'wuki - dadadada',
      'slander - dead (saymyname remix)',
      'zomboy - get with the program (trampa & eptic remix)',
      'barely alive - scoop (mayhem & antiserum remix)',
      'rickyxsan - gotta lean back (marct hard trap remix)',
      'ookay & getter - world',
      'rl grime & what so not & skrillex - waiting (freaky x frantzy pants remix)',
      'frank - reeeefer madness',
      'quix & troy kete - sippy cup',
      'dr fresch x andrew luce - hit it from the back',
      'rae sremmurd x flux x nghtmre - black beatles (benzi edit)',
      'rickyxsan - socal',
      'jayceeoh & lit lords - 2 tha bank',
      'major lazer - powerful (g-buck remix)',
      '8er$ x matsubs - burn babylon',
      'part native & holly - purge',
      'boombox cartel - alamo',
      'hydraulix & quix - jam dizzle',
      'justin martin - back to the jungle (dj marky remix)',
      'bassnectar & g jones - mind tricks',
      'major lazer - run up (sub focus remix)',
      'knife party & tom morello - battle sirens (riot remix)',
      'zeds dead & diplo - blame (dirtyphonics remix)',
      'eliminate - goodbye',
      'stelouse & myrne - call me',
      'dirtyphonics x virtual riot - beat dem up',
      'dirtyphonics & bassnectar - watch out',
      'terror squad - lean back (nghtmre remix)',
      'rezz x raito - alien',
      'pink guy x getter x nick colletti - hood rich',
      'mr carmack - faults',
      'social kid - graveyard',
      'skrillex & habstrakt - chicken soup',
      'dr fresch - sick',
      'zhu - nightcrawler (kyushu remix)',
      'nonsens - sabotage',
      'herobust - status busted (hydraulix & oski remix)',
      'sophie - msmsmsm',
      'weird together - ready for this (masayoshi iimori remix)',
      'wave racer - streamers',
      'skrillex - scary monsters & nice sprites (yookie vip)',
      'phiso - jotaro (yookie edit)',
    ],
  }, {
    name: 'Soft Mix',
    genre: 'Soft',
    image: 'softmix.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/soft-mix/',
    mp3: 'soft-mix.mp3',
    createdDate: '2017-06-19',
    length: '58:26',
    tracklist: [
      'caribou - cant do without you (manila killa & kidswaste cover)',
      'tennyson x mr carmack - tuesday',
      'florence & the machine - youve got the love (xx remix)',
      'lido - dye (mossy. remix)',
      'ekali - unfaith',
      'cmrn - thieves',
      'galimatias - leaving for good',
      'krne - on my way',
      'jea - inside out',
      'lido - catharsis',
      'memba - heat of the moment',
      'san holo - light (loosid remix)',
      'mura masa - miss you',
      'masego x medasin - sunday vibes',
      'blink-182 - feelin this (ryan hemsworth remix)',
      'louis the child - fire',
      'lido & santell - ashley',
      'whethan - cant hide',
      'moods - love is real',
      'rjd2 - june',
      'london grammar - wasting my young years (sound remedy remix)',
      'tritonal - still with me (seven lions remix)',
      'seven lions - days to come',
      'niykee heaton - infinity (illenium remix)',
      't-mass & ellusive - trapped in fourths',
      'felix snow - slow',
      'tritonal - anchor (unlike pluto remix)',
      'unlike pluto & kickraux - palace',
      'jocelyn alice - bound to you (pluto remix)',
      'porter robinson & madeon - shelter (slushii remix)',
      'said the sky - darling',
      'kiiara - whippin',
      'lotus - canvas',
      'aaron willem - flowerjazz',
      'hot sugar - mercy (ft. baghdaddy)',
      'daniel caesar - get you',
      'galantis - gold dust (illenium remix)',
      'hot sugar - sinkies',
      'farzam - when im gone',
      'towkio - heaven only knows',
      'gill chang - story',
    ],
  }, {
    name: 'Club Work Mix 2',
    genre: 'House',
    image: 'clubworkmix2.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/club-work-mix-pt-2/',
    mp3: 'club-work-mix-2.mp3',
    createdDate: '2017-05-04',
    length: '37:30',
    tracklist: [
      'tinashe - 2 on (wax motif remix)',
      'migos - bad and boujee (zhu remix)',
      'martin garrix & jay hardway - f for you (teed remix)',
      'dj snake - oh me oh my (malaa remix)',
      'ryan collins - still the same',
      'rae sremmurd - no type (nghtmre remix)',
      'tchami & malaa - prophecy',
      'benny benassi - satisfaction (gabe & victor ruiz & alex stein remix)',
      'bingo players - devotion',
      'malaa - contagious',
      'brohug - droppers',
      'athletixx - pnthr',
      'skrillex & must die! - vips',
      'wuki - talk (tony quattro remix)',
      'nina las vegas - ezy',
      'dej loaf - try me (nick catchdubs jersey gardens mix)',
      'flosstradamus - mfu (probcause remix)',
      'dombresky - utopia (4b remix)',
      'jack u - mind (4b remix)',
      'diplo & swick - keep it gully',
      'justin timberlake - senorita (trippy turtle remix)',
      'boys noize & salva - choo choo',
      'trippy turtle - slide',
      'spydat.e.k. - si me dices',
      'drippy dolphin - oooops',
      'clb - booty bounce (round2 edit)',
      'diplo x cl x riff raff x og maco - dr pepper (stevie g bootleg)',
      'smookie illson - club action (4b remix)',
      'space jesus - m night shambhala',
      'chris lake & anna lunoe - stomper (dr fresch remix)',
      'cashmere cat - throw myself a party',
      'travis scott x michael sparks - goosebumps (benzi vs yehme2 edit)',
      'jauz x eptic - get down',
      'jauz - claim to be',
      'mercer & saymyname - wanted',
    ],
  }, {
    name: 'Club Work Mix',
    genre: 'House',
    image: 'clubworkmix.gif',
    url: 'https://www.mixcloud.com/itsmowgli/club-work-mix/',
    mp3: 'club-work-mix.mp3',
    createdDate: '2017-02-10',
    length: '28:22',
    tracklist: [
      'bruckner - take you to the bronx',
      'vices - essa',
      'trippy turtle - controlla',
      'rich chigga - dat stick (hikeii flip)',
      'the knocks - heat (manila killa remix)',
      'migos - bad and boujee (dj taj remix)',
      'the presets - ghosts (hermitude trapped in heaven remix',
      'wiwek & alvaro - boomshakatak (flipn\'gawd jersey terror remix)',
      'trippy turtle - im sprung',
      'baauer - boog',
      'brenmar & dj sliink - bait',
      'travis scott - goosebumps',
      'crookers - king of africa',
      'jack u - take u there (tchami remix)',
      'chris lake & chris lorenzo - the calling',
      'sacha robotti - the dude abides',
      'anna lunoe - radioactive',
      'eptic - the end (carnage & breaux remix) (crankdat vip)',
      'skrillex & diplo - mind (malaa remix)',
      'missy elliott - wtf (where they from) (chris lake remix)',
      'dr dre & eminem - forgot about dre (drezo remix)',
      'alok & shapeless - who gives',
      'joyryde - hari kari',
      'chris lake & anna lunoe - stomper',
      'tittsworth & alvin risk - la campana',
      'drezo - heaven',
      'alok & liu - bolumback',
      'crookers - heavy',
      'j sutta - forever (shawn wasabi remix)',
      'benny benassi - cinema oui (benzi x skrillex x brycem88 edit)',
    ],
  }, {
    name: 'Trap Lounge Mix',
    genre: 'Vibey',
    image: 'traploungemix.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/trap-lounge-mix/',
    mp3: 'trap-lounge-mix.mp3',
    createdDate: '2016-12-27',
    length: '57:03',
    tracklist: [
      'juelz - sabotage',
      'schoolboy q - that part',
      'big sean - blessings (ekali remix)',
      'madeintyo - i want',
      'medasin - gold',
      'juelz - cocaine',
      'juelz - whine & kotch',
      'reid stefan - for the birds (ray volpe trap remix)',
      'michael sparks - ja rasta',
      'bromley - burn down',
      'riff raff - tip toe wing in my jawndiz (mr carmack and big makk remix)',
      'migos - bad and boujee',
      'travis scott - a-team (yugi boi remix)',
      'krne & quix - monuments',
      'que - og bobby johnson (aire atlantica & racecarbed remix)',
      'mura masa - hell',
      'rowland evans - now we live (vip)',
      'kayoh & nick noel - cook it up',
      'hounds - myria',
      'cavalier & ezra - one two',
      'coubo - its like love',
      'baauer - sout london (demo)',
      'big gigantic - brighter future',
      'flume - that look (vincent flip)',
      'zeds dead x nghtmre - frontlines',
      'lookas & crankdat - game over',
      'myrne & gill chang - pretty things',
      'slumberjack - enigma (vip)',
      'buku - freaknik (atliens remix)',
      'rae sremmurd - black beatles (makj remix)',
      '4b & duckworthsound - bring in the drums (vip)',
      'loudpvck & nghtmre - click clack',
      'nero - wont you (baauer remix)',
      'mayhem x antiserum - sweat (barely alive remix)',
      'ab the thief & scrvp - jefe',
      'cray - btfu',
      'kill the noise & madsonik - spitfire riddim (boombox cartel remix)',
      'ray volpe - better',
      'louis the child - its strange (boombox cartel remix)',
      'boombox cartel & quix - supernatural',
      'grandtheft & delaney jane - easy go',
      'slumberjack - open fire (enschway remix)',
      'seven lions - creation (soltan remix)',
      'rl grime, what so not, skrillex - waiting',
      'lido - murder',
      'ray volpe - bipolar',
      'ldru - next to you (barely alive & virtual riot remix)',
      'hex cougar - sweet dreams (instrumental)',
      'slander & yookie - after all',
      'graves & coolights - say things (crankdat re-crank)',
      'triv - leche',
      'krne x ghasper - fake friends',
      'oski - super hot',
      'kayoh x stonewall klaxon - limes',
      'gangus - my way',
      'landyn - joypop',
      'rl grime - valhalla',
      'mat zo - take it back',
      'drake - no tellin (zeds dead remix)',
    ],
  }, {
    name: 'Hitting The Bottle Mix',
    genre: 'Hard',
    image: 'hittingthebottlemix.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/hitting-the-bottle-mix/',
    mp3: 'hitting-the-bottle-mix.mp3',
    createdDate: '2016-08-08',
    length: '57:41',
    tracklist: [
      'juelz - drifttt',
      'lykke li - i follow rivers (the oos and aahs remix)',
      'palette - girlfriend',
      'josh pan & x&g - platinum',
      'delay - dao',
      'hi tom - splits',
      'eptic - the end (breaux vip)',
      'krs. - rnb ting',
      'the game & skrillex - el chapo (quix remix)',
      'medasin & x&g - the zoo',
      'kill the noise - dolphin on wheels',
      'victor niglio - locust',
      'krne - ascension',
      'drake - hype',
      'stooki sound & mr carmack - uppers',
      'destructo - 4 real (drezo remix)',
      'skrillex - fuck that',
      'cavalier x krne - hands tied',
      'vincent - no mercy',
      'hudson mohawke - chimes (alexander lewis x y2k flip)',
      'madeintyo - uber everywhere (slushii remix)',
      'snails - murda sound',
      'herobust - pipette up',
      'skism - experts (barely alive remix)',
      'joyryde - the box (crankdat re-crank)',
      'dubloadz - cuck life',
      'herobust - bottle swervice',
      'barely alive - scoop',
      'mr carmack - pay for what (alexander lewis trombone flip)',
      'mr vandal x gangus - lean like tea time',
      'ghastly - get on this (ghastly x ablaze vip)',
      'getter & adair - blood',
      'wiwek & skrillex - killa (bommbox cartel & aryay remix)',
      'jaykode - beehive',
      'cray - 4never',
      'jack u -to u',
      'tascione x tynvn - deeper love',
      'skrillex & diplo - mind (ekali & gravez remix)',
      'aya - hours',
      'mura masa - what if i go?',
      'griz - fall in love too fast',
      'the him - dont leave without me',
      'graves & myrne - tiger blood',
      'cade - care',
    ],
  }, {
    name: 'Catching F\'s',
    genre: 'Soft',
    image: 'catchingfs.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/catching-fs/',
    mp3: 'catching-fs.mp3',
    createdDate: '2016-07-04',
    length: '55:45',
    tracklist: [
      'heavy mellow - want u 2 know (slow dance interlude)',
      'mura masa - lovesick fuck',
      'illenium - only one',
      'mt wolf - life size ghosts (catching flies remix)',
      'alina baraz & galimatias - drift',
      'what so not - touched',
      'jurassic 5 - thin line',
      'soysauce - broken record (louis the child remix)',
      'aire atlantica - april',
      'drake - controlla',
      'koan sound, culprate, asa & opiuo - if you hadnt',
      'lido - lost (heavy mellow remix)',
      'flume - never be like you',
      'snakehips - money on me',
      'rufus - you were right (louis futon remix)',
      'whispa - shinobi flow',
      'vaski - 3am (swindail remix)',
      'hxns x r.o.m. x lege kale - blues',
      'tennyson - with you',
      'cosmos midnight - falling out',
      'clams casino - im god',
      'halch - habits',
      'numbernin6 - what youve done',
      'illenium - fortress',
      'seven lions - worlds apart',
      'cosmos midnight - trapped',
      'panama - destroyer (cosmos midnight remix)',
      'nghtmre & slander - gud vibrations (b-sides remix)',
      'wave racer - world record',
      'flume - say it',
      'boombox cartel x quix x tove lo - supernatural x high (ekali flip)',
      'bnjmn & oshi - 808luv',
      'ghost loft - so high',
      'slander - love again',
    ],
  }, {
    name: 'Meze Mix 2',
    genre: 'House',
    image: 'mezemix2.gif',
    url: 'https://www.mixcloud.com/itsmowgli/meze-mix-2/',
    mp3: 'meze-mix-2.mp3',
    createdDate: '2016-05-07',
    length: '42:07',
    tracklist: [
      'robin schulz - sugar',
      'hi-lo - crank it up',
      'kid ink - be real (wax motif & gladiator remix)',
      'skrillex & must die! - vips',
      'kevin gates - 2 phones',
      'valentino khan - deep down low',
      'tiga - bugatti (jauz remix)',
      'maya jane coles - what they say',
      'justin bieber - what do u mean',
      'disclosure - f for u (teed remix)',
      'jauz - feel the volume',
      'joyryde - flo',
      'disclosure - bang that',
      'major lazer - pon de floor',
      'major lazer - aerosol can',
      'enur - calabria 2007',
      'tujamo & plastic funk - who',
      'oliver heldens - koala',
      'feed me x kaneholler - trouble',
      'shiba san - okay',
      'jauz - deeper love',
      'oliver heldens - gecko',
      'steve edwards - love like water (toddla t remix)',
      'sandro silva - told ya (apster remix)',
      'feed me - chain smoker',
      'disclosure - when a fire starts to burn',
      'dog blood - middle finger',
      'wiwek & gregor salto - trouble',
      'loudpvck - lit',
      'gta - the crowd',
      'destructo - party up (gta remix)',
      'cavalier - hands tied',
      'drake - pop style',
      'kirko bangz - drank in my cup',
    ],
  }, {
    name: 'Meze Mix',
    genre: 'Variety',
    image: 'mezemix.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/meze-mix/',
    mp3: 'meze-mix.mp3',
    createdDate: '2016-05-07',
    length: '1:09:12',
    tracklist: [
      'jay-z - fuckwithmeyouknowigotit',
      'juicy j - bandz a make her dance',
      '2 chainz - where you been',
      'migos - pipe it up',
      'asap ferg - work (remix)',
      'fetty wap - my way (remix)',
      'kaaris - charge (mr carmack remix)',
      'travis scott - antidote',
      'ot genasis - cut it',
      'what so not - high you are (branchez remix)',
      'rae sremmurd - throw sum mo',
      'fetty wap - trap queen (kasbo remix)',
      'drake - hotline bling (ookay remix)',
      'gent & jawns - turnup',
      'future - fuck up some commas',
      'young thug - stoner (flosstradamus remix)',
      'midnight tyrannosaurus - basement bitch',
      'kendrick lamar - poetic justice',
      'wiz khalifa - bake sale',
      'hermitude - the buzz',
      'jack u - where r u iverson (flosstradamus edit)',
      '2chainz - birthday song',
      'ludacris - how low',
      'baauer - yaow',
      'herobust - skurt reynolds',
      'kanye west - mercy',
      'trinidad james - all good everything (prototype & carnage remix)',
      'chris brown - kiss kiss',
      'tnght - buggn',
      'desiigner - panda',
      'mura masa - lotus eater',
      'yo gotti - down in the dms (nicki minaj remix)',
      'kanye west - facts (charlie heat version)',
      'jack u - febreze',
      'diplo & alvaro - 6th gear (gta remix)',
      'kanye west - new slaves (dj snake remix)',
      'flosstradamus & nghtmre - lighters up',
      'diplo - revolution (unlike pluto remix)',
      'boombox cartel - b2u',
      'kendrick lamar - backseat freestyle (edit)',
      'baauer - dum dum',
      'yogi - burial (skrillex remix) (g-buck edit)',
      'nghtmre - touch',
      'disclosure - you & me (flume remix)',
      'what so not - gemini',
      'dillon francis - masta blasta (the rebirth)',
      'cray - up in smoke',
      'rae sremmurd - no type (party favor remix)',
      'ray volpe - by your side (cray remix)',
      'louis the child - its strange (boombox cartel remix)',
      'jack u - holla out (vip)',
      'flosstradamus & ricky remedy - byb',
      'major lazer - roll the bass',
      'debroka - industry',
      'migos - look at my dab (diplo & bad royale remix)',
      'dj fresh vs diplo - earthquake',
      'rich homie quan - flex',
      'valentino khan - deep down low (g-buck edit)',
      'diplo - express yourself (gent & jawns remix)',
      'drake - crew love ft. the weeknd',
      'kanye west - clique (keys n krates remix)',
      'flux pavilion - exostomp (diskord remix)',
      'the chainsmokers - dont let me down',
      'jason derulo - wiggle',
    ],
  }, {
    name: 'Fall Mix 2015',
    genre: 'Variety',
    image: 'fallmix.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/pumpkin-mix/',
    mp3: 'fall-mix-2015.mp3',
    createdDate: '2015-10-28',
    length: '56:34',
    tracklist: [
      'mura masa - low',
      'hi-lo - crank it up',
      'oliver heldens & shaun frank - shades of grey (nora en pure remix)',
      'nicki minaj, drake & lil wayne - truffle butter (aston shuffle remix)',
      'tinie tempah - not letting go (troyboi remix)',
      'kaaris - charge (mr carmack remix)',
      'asap ferg - work (remix)',
      'katy perry - this is how we do (grandtheft remix)',
      'rl grime - kingpin (wuki remix)',
      'loudpvck - lit',
      'jack u - jungle bae',
      'zeds dead & loudpvck - flies',
      'gta - the crowd',
      'destructo - party up (gta remix)',
      'nadus - nwxrk',
      'alessia cara - here (lucian remix)',
      'the shanghai restoration project - jessfield park (instrumental)',
      'wave racer - flash drive',
      'lido & canblaster - hyperspeed',
      'justin timberlake - senorita (trippy turtle remix)',
      'goldlink - dance on me (pyrmdplaza remix)',
      'future - honest (dj hoodboi & arnold remix)',
      'alison wonderland - u dont know (xvii flip)',
      'loudpvck & nipsey hussle - tony',
      'asap ferg - murda something (xvii remix)',
      'midnight tyrannosaurus - basement bitch (p0gman remix)',
      'marshmello - show you',
      'jennifer paige - crush (goshfather & jinco x jaykode edition)',
      'zomboy - terror squad (bro safari & ricky remedy remix)',
      'dillon francis - masta blasta (the rebirth)',
      'mura masa - lotus eater',
      'what so not - gemini',
      'party thieves & atliens - chief (nebbra remix)',
      'nghtmre - street',
      'nghtmre & slander - gud vibrations',
      'wave racer - world record',
      'major lazer - lean on (dillon francis & jauz remix)',
      'rl grime - heard me',
      'flosstradamus & ricky remedy - byb',
      'keys and krates - i just cant deny',
      'flux pavilion - exostomp (diskord remix)',
      'rick ross - movin bass (gta remix)',
      'nghtmre & slander - warning',
      'grandtheft - let me tell you something',
      'rl grime - valhalla',
      'barely alive - wedabe$ (phase one remix)',
      'kodak to graph - iamanthem (big wild remix)',
      'jonah baseball - louisville slugger',
    ],
  }, {
    name: 'Bouncy Mix',
    genre: 'Older',
    image: 'bouncymix.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/bouncy-mix/',
    mp3: 'bouncy-mix.mp3',
    createdDate: '2014-11-12',
    length: '20:57',
    tracklist: [
      'panama - destroyer (cosmos midnight remix)',
      'cosmos midnight - goodnight',
      'm.i.a. - bad girls',
      'vanic & k flay - cant sleep',
      'hex cougar - allnyte',
      'the m machine - ghosts in the machine (kill the noise remix)',
      'zeds dead - adrenaline',
      'midnight tyrannosaurus - basement bitch (p0gman remix)',
      'asap ferg - murda something (xvii remix)',
      'jack beats - get down (uz remix)',
      'diplo & alvaro - 6th gear',
      'skrillex - ease my mind (jai wolf remix)',
      'dillon francis - when we were young (zomboy remix)',
    ],
  }, {
    name: 'Octo Mix',
    genre: 'Older',
    image: 'octopartymix.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/octopartymix/',
    mp3: 'octo-mix.mp3',
    createdDate: '2014-11-09',
    length: '57:30',
    tracklist: [
      'clipse - gangsta lean',
      'gramatik - muy tranquilo',
      'dr dre - big egos',
      'geto boys - good to be a gangsta',
      'big tymers - still fly',
      'jurassic 5 - concrete schoolyard',
      'clipse - me too',
      'snoop dogg - drop like its hot',
      'birdman - what happened to that boy',
      'rjd2 - ghostwriter',
      'griz - you got to change',
      'gramatik - obviously',
      'xzibit - alocholik',
      'lettuce - slippin into darkness (griz remix)',
      'lynyrd skynyrd vs nelly - sweet home alabama vs country grammar',
      'major lazer - bubble butt (remix)',
      'goldfinger - superman',
      'blink-182 - first date',
      'mystikal - shake ya ass',
      'chris brown - loyal',
      'b.o.b - headband',
      'dj snake - turn down fro what',
      't.i. - no mediocre (grandtheft remix)',
      'cashmere cat - rice rain (twinztrack remix)',
      'dj snake - bird machine',
      'missy elliott - work it',
      'lil jon & the east side boyz - get low',
      'a-trak & milo & otis - out the speakers',
      'baauer - slip',
      'griz - dtw to dia (the travels of mr b)',
      'skrillex - the reason',
      'drake - 0 to 100 (grandtheft bootleg)',
      'griz - smash the funk',
      'griz - simple (ft. the floozies)',
      'big data - dangerous (oliver remix)',
      'fatboy slim - praise you',
    ],
  }, {
    name: 'JP\'s Summer Mix',
    genre: 'Older',
    image: 'summermix2013.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/jps-summer-mix-2013/',
    mp3: 'jps-summer-mix-2013.mp3',
    createdDate: '2013-06-18',
    length: '1:02:43',
    tracklist: [
      'major lazer - bubble butt (remix)',
      'major lazer - watch out for this (buyame)',
      'diplo - set it off (thefatrat remix)',
      'anita baker - ring my bell (kill paris remix)',
      'lets be friends - best in the west',
      'britney spears & will.i.am - scream & shout (melker project moombahpop remix)',
      'disclosure - when a fire starts to burn',
      'dog blood - middle finger',
      'knife party - power glove',
      'dog blood - next order',
      'oliver - myb',
      'diplo & swick - keep it gully',
      'oliver - control',
      'ke$ha ft will.i.am - crazy kids',
      'a-trak - disco nap',
      'lil wayne - rich as fuck',
      'brenmar & dj sliink - bait',
      'griz - too young for tragedy',
      'b.o.b - we still in this bitch',
      'trinidad james - all good eevrything (prototype & carnage festival remix)',
      'lazy rich & hirshee - damage control (prfftt & syvable dubstep remix)',
      'pegboard nerds - self destruct',
      'bassnectar - freestyle',
      'mandy capistro - the way i like it (mutrix remix)',
      'dwntwn - transition (dillon francis remix)',
      'rusko - ravers special',
      'skrillex - scary bolly dub',
      'alvin risk - wash dat',
      'designer drugs & alvin risk - empty hearted',
      'nero - crush on you',
      'showtek & noisecontrollers - get loose',
      'tnght - acrylics',
      'kid kamillion - say whut!',
      'benny benassi - satisfaction (rl grime remix)',
      'macklemore - cant hold us (unlike pluto remix)',
      'dillon francis - masta blasta (the rebirth)',
      'psy - gangnam style (diplo remix)',
      'kanye west - new slaves',
      'flosstradamus & dj sliink - crowd ctrl',
      'gent & jawns - turnup',
      'mayhem & antiserum - brick squad anthem',
      'b.o.b - out of my mind (clark kent remix)',
      'tnght - higher ground',
      'lil jon - snap your fingers (candyland\'s og remix)',
      'pegboard nerds - 20k',
      'zomboy - nuclear',
      'afk & spenca - who wants some?',
      'bassnectar - infinite',
      'flosstradamus - assquake',
      'demi lovato - heart attack',
      'sub focus - tidal wave',
    ],
  }, {
    name: 'Mostly Cats Mix',
    genre: 'Older',
    image: 'mostlycatsmix.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/mostly-cats/',
    mp3: 'mostly-cats-mix.mp3',
    createdDate: '2013-03-20',
    length: '22:49',
    tracklist: [
      'flosstradamus - total recall',
      'usher - climax (flosstradamus & diplo remix)',
      'calvin harris - sweet nothing (diplo & grandtheft remix)',
      'chuckie - who is ready to jump (dillon francis remix)',
      'baauer - yaow!',
      'baauer - harlem shake',
      'etc!etc! & brillz & diplo - bueller',
      'zedd - clarity (brillz remix)',
      'dillon francis - masta blasta (the rebirth)',
      'gent & jawns - turnup',
      'sleigh bells - demons (diplo remix)',
      'nero - wont you (baauer remix)',
      'diplo - express yourself (gent & jawns remix)',
      'kanye west - clique (keys n krates remix)',
      'flosstradamus - rollup (baauer remix)',
      'pixies - where is my mind? (bassnectar remix)',
    ],
  }, {
    name: 'Hammie Mix',
    genre: 'Older',
    image: 'hammiemix.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/hammie/',
    mp3: 'hammie-mix.mp3',
    createdDate: '2013-03-02',
    length: '28:58',
    tracklist: [
      'porter robinson - unison (knife party remix)',
      'css - hits me like a rock (dillon francis remix)',
      'dillon francis - bootleg fireworks (burning up)',
      'feed me - one click headshot',
      'diplo - butters theme',
      'nervo - like home (dillon francis remix)',
      'dillon francis - masta blasta',
      'kito & reija lee - broken hearts (dillon francis remix)',
      'dillon francis & diplo - que que',
      'dillon francis - brazzers theme',
      'diplo - move around',
      'diplo - barely standing',
      'dave nada - moombahton',
      'munchi - sandungueo',
      'nadastrom - i',
      'tittsworth & alvin risk - pendejas',
      'sidney samson - riverside (dave nada moombahton edit)',
    ],
  }, {
    name: 'Nemo Mix',
    genre: 'Older',
    image: 'nemomix.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/dirty-nemo-dubstep-mix/',
    mp3: 'nemo-mix.mp3',
    createdDate: '2013-02-09',
    length: '28:56',
    tracklist: [
      'kill the noise - jump ya body',
      'kanye west - mercy',
      'porter robinson - spitfire',
      'skrillex - imma try it out',
      'major lazer - keep it goin louder',
      'baauer - yaow!',
      'amanda blank - might like you better (vaski remix)',
      'birdy nam nam - goin in (skrillex\'s goin down mix)',
      'ibenji - broken box',
      'ibenji - kcn',
      'j rabbit - sexy party',
      'excision & datsik - swagga (downlink remix)',
      'excision & datsik - swagga (datsik\'s trap vip)',
      'isquare - hey sexy lady (skrillex remix)',
      'skrillex - right on time',
      'griz - the future is now',
      'skrillex - the reason',
      'notorious big - going back to cali (viceroy jet life remix)',
    ],
  }, {
    name: 'Gloss Boss Mix',
    genre: 'Older',
    image: 'glossbossmix.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/gloss-boss/',
    mp3: 'gloss-boss-mix.mp3',
    createdDate: '2013-01-04',
    length: '37:25',
    tracklist: [
      'cazzette - beam me up',
      'spencer & hill, nadia ali - believe it (cazzette remix)',
      'coldplay - paradise (fedde le grand remix)',
      'krewella - cant control myself (candyland remix)',
      'swedish house mafia - save the world (knife party remix)',
      'porter robinson - language (jacklndn edit)',
      'goodwill, hook n sling - take you higher',
      'avicii & nicky romero - i could be the one (nicktim)',
      'nervo & nicky romero - like home',
      'zedd - follow you down',
      'project 46 - the rigt',
      'deadmau5 - the veldt (project 46 remix)',
      'nervo & hook n sling - reason',
      'tourist - your girl',
      'zedd - hourglass',
      'calvin harris - thinkin about you',
      'basto - again and again',
      'chris malinchak - so good to me',
    ],
  }, {
    name: 'Buttered Muffins Mix',
    genre: 'Older',
    image: 'butteredmuffinsmix.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/buttered-muffins/',
    mp3: 'buttered-muffins-mix.mp3',
    createdDate: '2012-12-15',
    length: '54:10',
    tracklist: [
      'ke$ha - die young (remix)',
      'datsik & kill the noise - lightspeed',
      'nero - must be the feeling',
      'the prodigy - breathe (zeds dead remix)',
      'pendulum - the island, part ii (dusk)',
      'popeska - karmameter',
      'porter robinson - say my name',
      'major lazer - original don (flosstradamus remix)',
      'etc!etc! & brillz - swoop',
      'french montana - pop that',
      'doctor p - watch out',
      'bassnectar - vava voom',
      'knife party - centipede',
      'steve aoki - earthquakey people (alvin risk remix)',
      'morgan page ft. tegan and sara - body work (revolvr remix)',
      'benga - pour your love',
      'nero - wont you (be there)',
      'kill the noise - dying',
      'lil flip - way we ball (crizzly remix)',
      'meek mill - amen (xaphoon jones bootleg)',
      'datsik - bonafide hustler (trap vip)',
      'chief keef - i dont like (aylen & dotcom remix)',
      'kanye west - mercy (rl grime & salva remix)',
      'yung joc - goin down (milo & otis unoriginal mix)',
      'nero - promises',
      'dabin - tell me',
      'nero - me & you',
      'krewella - come get it',
      'sub focus - tidal wave',
      'monsta - holdin on (skrillex & nero remix)',
      'bassnectar - bass head',
      'pixies - where is my mind? (bassnectar remix)',
      'bassnectar & datsik - yes',
      'knife party - bonfire',
      'bassnectar - upside down',
      'skrillex - kyoto',
      'porter robinson - spitfire (kill the noise remix)',
      'zion i - human (bassnectar remixxx)',
    ],
  }, {
    name: 'White Russians Mix',
    genre: 'Older',
    image: 'whiterussiansmix.jpg',
    url: 'https://www.mixcloud.com/itsmowgli/white-russians/',
    mp3: 'white-russians-mix.mp3',
    createdDate: '2012-10-15',
    length: '44:26',
    tracklist: [
      'chris brown - dont wake me up (thefatrat remix)',
      'avicii - last dance',
      'hardwell - cobra',
      'rebecca stella - give me that o (skeet skeet remix)',
      'tiesto & hardwell - zero 76',
      'hardwell - call me a spaceman',
      'wolfgang gartner - love & war',
      'tiesto & wolfgang gartner - we own the night',
      'skylar grey - dance without you (alvin risk remix)',
      'fun - we are young (alvin risk remix)',
      'zedd & lucky date - fall into the sky',
      'skrillex & wolfgang gartner - devils den',
      'zedd - shave it up',
      'will smith - miami',
      'dillon francis & diplo - que que',
      'oh my! - dirty dancer (alvin risk remix)',
      'tittsworth & alvin risk - pendejas',
      'krewella - cant control myself',
      'steve aoki & angger dimas - beat down',
      'dillon francis & dave nada - brazzers theme',
      'dillon francis - idgafos',
      'diplo - no problem',
      'skrillex & damian marley - make it bun dem (butch clancy remix)',
      'sticky k - persian algebra',
      'time takers - she blows (whistle song) (dillon francis remix)',
    ],
  },
];
