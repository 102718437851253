<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Home from '@/views/Home.vue';

export default {
  name: 'App',
  components: { Home },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Muli:300,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');

@font-face {
  font-family: 'OxinBrush';
  src: url('~@/assets/fonts/Typo_Oxin_Brush.ttf') format('truetype');
}

body {
  margin: 0;
  min-height: 100vh;
  padding: 0;
}

* { box-sizing: border-box; }

#app {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  bottom: 0;
  color: #2c3e50;
  font-family: 'Muli', Helvetica, Arial, sans-serif;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
}
</style>
