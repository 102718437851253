const fallScene = {
  bgImage: 'bg1.jpg',
  bgColor: '#4f3523',
};

const blueWaves = {
  bgImage: 'bg2.jpg',
  bgColor: '#1b2e36',
};

const greenTrees = {
  bgImage: 'bg3.jpg',
  bgColor: '#2f3b1c',
  lightBackground: true,
};

const cloudyForest = {
  bgImage: 'bg4.jpg',
  bgColor: '#28323b',
};

const cloudyMountain = {
  bgImage: 'bg5.jpg',
  bgColor: '#452e26',
  lightBackground: true,
  bgPosition: '0 21%',
};

const waterfall = {
  bgImage: 'bg6.jpg',
  bgColor: '#121a19',
  bgPosition: '0% 40%',
};

const dewyGrass = {
  bgImage: 'bg8.jpg',
  bgColor: '#222',
  bgPosition: '0% 70%',
};

const darkLeaves = {
  bgImage: 'bg9.jpg',
  bgColor: '#222',
};

const snowyTrees = {
  bgImage: 'bg10.jpg',
  bgColor: '#ccc',
  bgPosition: '0% 40%',
};

export default [
  {
    name: 'Vibey',
    displayName: 'Fall Mix Series',
    ...darkLeaves,
  }, {
    name: 'Hard',
    displayName: 'Festival Mix Series',
    ...cloudyMountain,
  }, {
    displayName: 'House & Club',
    name: 'House',
    ...greenTrees,
  }, {
    name: 'Soft',
    ...dewyGrass,
  }, {
    name: 'Variety',
    ...blueWaves,
  }, {
    name: 'Older',
    ...fallScene,
  },
];
